import Tooltip from '@mui/material/Tooltip'

const positionColumn = {
  field: 'position',
  width: 15,
  maxWidth: 15,
  minWidth: 15,
  sortable: false,
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip title="Posição do anuncio na busca." arrow>
      <span>
        #
      </span>
    </Tooltip>
  ),
  renderCell: params => params.row.position,
}

export default positionColumn