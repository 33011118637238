import TipsMenu from '../TipsMenu'

const titleColumn = {
  field: 'title',
  headerName: 'Título',
  width: 380,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <TipsMenu {...params.row} cellValue={params.row.title} />,
}

export default titleColumn