import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from './PrivateRoute'
import PermissionRoute from './PermissionRoute'

import './App.css'

/*
// Site Pages
import PageHome from './pages/site/PageHome'
import PageResources from './pages/site/PageResources'
import PagePrices from './pages/site/PagePrices'
*/

// Auth
import PageLogin from './pages/site/PageLogin'
import PageRegister from './pages/site/PageRegister'
import PagePasswordRecover from './pages/site/PagePasswordRecover'

// Configs
import PageSlackConfiguration from './pages/panel/configurations/PageSlackConfiguration'

// Panel Pages
import PageNotFound from './pages/panel/PageNotFound'
import PagePanelHome from './pages/panel/PagePanelHome'
import PageAnns from './pages/panel/PageAnns'
import PageAnnDetails from './pages/panel/PageAnnDetails'
import PagePowerSearch from './pages/panel/PagePowerSearch'
import PagePowerSearch2 from './pages/panel/PagePowerSearch2'
import PageConcurrent from './pages/panel/PageConcurrent'
import PageOrders from './pages/panel/PageOrders'
import PageProducts from './pages/panel/PageProducts'
import PageConciliation from './pages/panel/PageConciliation'
import PageConciliationPayments from './pages/panel/PageConciliationPayments'
import PageProfile from './pages/panel/PageProfile'
import PageStore from './pages/panel/PageStore'

import PageAnns_Default from './pages/panel/PageAnns_Default'
import PageAnns_Quantity from './pages/panel/PageAnns_Quantity'
import PageAnns_Quantity2 from './pages/panel/PageAnns_Quantity2'
import PageAnns_Price from './pages/panel/PageAnns_Price'
import PageAnns_Relist from './pages/panel/PageAnns_Relist'
import PageAnns_Metrics from './pages/panel/PageAnns_Metrics'

import PageDevolutions from './pages/panel/PageDevolutions'
import PageMeliNotifications from './pages/panel/PageMeliNotifications'

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        {/*
        <Route path="/" element={<PageHome />} />
        <Route path="/resources" element={<PageResources />} />
        <Route path="/prices" element={<PagePrices />} />
        */}
        
        <Route path="/login" element={<PageLogin />} />
        <Route path="/register" element={<PageRegister />} />
        <Route path="/password-recover" element={<PagePasswordRecover />} />
        
        <Route path='/' element={<PrivateRoute />}>
          
          <Route path="/*" element={<PageNotFound />} />
          
          <Route
            path="/"
            element={<Navigate to="/panel" />}
          />
          <Route
            path="/panel"
            element={<PagePanelHome />}
          />
          <Route
            path="/panel/anns"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns />}
              />
            }
          />
          <Route
            path="/panel/anns/default"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Default />}
              />
            }
          />
          <Route
            path="/panel/anns/quantity"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Quantity />}
              />
            }
          />
          <Route
            path="/panel/anns/quantity2"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Quantity2 />}
              />
            }
          />
          <Route
            path="/panel/anns/price"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Price />}
              />
            }
          />
          <Route
            path="/panel/anns/relist"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Relist />}
              />
            }
          />
          <Route
            path="/panel/anns/metrics"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageAnns_Metrics />}
              />
            }
          />
          <Route
            path="/panel/ann-details"
            element={
              <PermissionRoute
                permission='access_anns_details'
                element={<PageAnnDetails />}
              />
            }
          />
          <Route
            path="/panel/power-search"
            element={
              <PermissionRoute
                permission='access_power_search'
                element={<PagePowerSearch />}
              />
            }
          />
          <Route
            path="/panel/power-search2"
            element={
              <PermissionRoute
                permission='access_power_search'
                element={<PagePowerSearch2 />}
              />
            }
          />
          <Route
            path="/panel/concurrent"
            element={
              <PermissionRoute
                permission='access_concurrent'
                element={<PageConcurrent />}
              />
            }
          />
          <Route
            path="/panel/orders"
            element={
              <PermissionRoute
                permission='access_orders'
                element={<PageOrders />}
              />
            }
          />
          <Route
            path="/panel/products"
            element={
              <PermissionRoute
                permission='access_orders'
                element={<PageProducts />}
              />
            }
          />
          <Route
            path="/panel/conciliation"
            element={
              <PermissionRoute
                permission='access_conciliation'
                element={<PageConciliation />}
              />
            }
          />
          <Route
            path="/panel/conciliation/payments"
            element={
              <PermissionRoute
                permission='access_conciliation'
                element={<PageConciliationPayments />}
              />
            }
          />
          <Route
            path="/panel/profile"
            element={
              <PermissionRoute
                permission='access_profile'
                element={<PageProfile />}
              />
            }
          />
          <Route
            path="/panel/store"
            element={
              <PermissionRoute
                permission='access_store'
                element={<PageStore />}
              />
            }
          />
          <Route
            path="/panel/mercadolivre/notifications"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageMeliNotifications />}
              />
            }
          />
          <Route
            path="/panel/mercadolivre/devolutions"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageDevolutions />}
              />
            }
          />
          <Route
            path="/panel/configurations/slack"
            element={
              <PermissionRoute
                permission='access_anns'
                element={<PageSlackConfiguration />}
              />
            }
          />

        </Route>
        
      </Routes>
    </BrowserRouter>
  );
}