import Box from '@mui/material/Box'

import TipsMenu from '../TipsMenu'

const sellerColumn = {
  field: 'seller_nickname',
  headerName: 'Vendedor',
  width: 150,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => (
    // This Box allows '...' when the text is too long
    <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >
      <TipsMenu {...params.row} cellValue={params.row.seller_nickname} tooltip />
    </Box>
  ),
}

export default sellerColumn