import TipsMenu from '../TipsMenu'

const thumbnailColumn = {
  field: 'thumbnail',
  headerName: '',
  width: 75,
  padding:0,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <TipsMenu {...params.row} cellValue={
    <img style={{
      width: 75,
      height: 75,
      //padding: '5px',
      marginTop: '3px',
    }} src={params.row.thumbnail} />
  } />,
}

export default thumbnailColumn