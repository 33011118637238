import TipsMenu from '../TipsMenu'

const idColumn = {
  field: 'id',
  headerName: 'ID',
  width: 100,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <TipsMenu {...params.row} cellValue={params.id} />,
}

export default idColumn