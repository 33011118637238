import { useState, useEffect } from 'react'
import { useAuth } from '../../../AuthContext'
import { useNavigate } from "react-router-dom"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'

import Search from './Search'
import AnnsList from './AnnsList'
import fetch_items from './fetch_items'

import HasNotIntegration from '../HasNotIntegration'

function PageAnns () {

	const navigate = useNavigate()
	const { token } = useAuth()

	const [items, setItems] = useState([])
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const [search, setSearch] = useState('')
	const [initialSearch, setInitialSearch] = useState('')

	// Realiza a busca pelos anúncios
	const fetch_search = async (search) => {
		setErrorMessage(false)
		setLoading(true)

		const response = search !== ''
			? await fetch_items(search, token)
			: []
		
		if (!response.error) setItems(response)
		else setErrorMessage(response.error)
		
		setLoading(false)
	}

	// Caso uma busca seja definida
	useEffect(() => {
		if (search) {
			fetch_search(search)
		
			const queryParams = new URLSearchParams(window.location.search)
			queryParams.set('search', search)
			navigate(`?${queryParams.toString()}`)
		}
	}, [search])

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		if (search_url_query) {
			setSearch(search_url_query)
			setInitialSearch(search_url_query)
		}
	}, [])

	return <Layout>
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={

		    	<Grid container spacing={2} style={{padding:'20px 0'}}>
	      	
		      	<Grid lg={12}>
							<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Super Busca
			      	</Typography>

							<Typography
			      		variant="p"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Faça uma busca como se estivesse no mercado livre, e tenha informações mais detalhadas dos anúncios concorrentes.
			      	</Typography>
			      </Grid>

			      <Grid lg={3}>
							<Search
								handleSearch={setSearch}
								initialSearch={initialSearch}
							/>
			      </Grid>

						{errorMessage && <Grid lg={12}><p>{errorMessage}</p></Grid>}

						{!errorMessage && <Grid lg={12}>
							{loading
								? <p>Carregando os anúncios... Isso pode demorar um pouco.</p>
								: <AnnsList items={items} />
							}
			      </Grid>}
				  </Grid>

				}/>

		  </Box>
		</Container>
	</Layout>

}

export default PageAnns