import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

export default function TableHeader () {

  return <TableHead>
    <TableRow
      sx={{
        '& > .MuiTableCell-root': {
          padding: '0 5px',
          fontSize: '11px',
          borderBottom: 'none'
        },
      }}
    >
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell align="center" colSpan={5} sx={{ borderLeft: '3px solid #eee', borderRight: '3px solid #eee' }}>Visitas</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
    <TableRow
      sx={{
        '& > .MuiTableCell-root': {
          padding: '0 5px',
          fontSize: '11px'
        },
      }}
    >
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell align="center" sx={{ borderLeft: '3px solid #eee' }}>7 Dias</TableCell>
      <TableCell align="center">14 Dias</TableCell>
      <TableCell align="center">30 Dias</TableCell>
      <TableCell align="center">Totais</TableCell>
      <TableCell sx={{ borderRight: '3px solid #eee' }}/>
      <TableCell align="right">Preço</TableCell>
      <TableCell align="right">Tarifas</TableCell>
      <TableCell align="right">Frete</TableCell>
      <TableCell align="right">Repasse</TableCell>
    </TableRow>
  </TableHead>

}